import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import VisualNavigation from "Layout/Navigation/VisualNavigation/VisualNavigation"
import RelatedProjects from "Layout/Projects/RelatedProjects/RelatedProjects"

const ResearchPractice = ({
  data,
  location
}) => {

  const {page, researchTracks, optionsPage, projects} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  const researchPracticeProjects = projects.edges.filter(
    project => project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    ).length > 0 && project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    )[0].name.toLowerCase() !== "Global Education".toLowerCase()
  )

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      <VisualNavigation items={researchTracks} genericImages={gI} classes="mb-7" />
      <RelatedProjects projects={researchPracticeProjects} headline="Research Projects" />
    </MainLayout>
  )
}

export const query = graphql`
  query researchPracticePageQuery {
    page: wordpressPage(wordpress_id: {eq: 29}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    researchTracks: allWordpressWpResearchTracks {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    projects: allWordpressWpProjects {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 684) {
                  src
                  width
                  height
                  originalName
                }
              }
            }
          }
          categories_names {
            name
            taxonomy
          }
        }
      }
    }
  }
`

export default ResearchPractice
